import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchLeadsReport(ctx,) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('homeLeadReport',)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

 
  },
};
